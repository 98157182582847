import Container_ResponsiveBoxComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/ResponsiveBox/ResponsiveBox.skin';


const Container_ResponsiveBox = {
  component: Container_ResponsiveBoxComponent
};


export const components = {
  ['Container_ResponsiveBox']: Container_ResponsiveBox
};

